module.exports = (env) => {
    env.addFilter('format_currency', (value, currency = 'EUR', attrs = [], locale = undefined) => {
        locale = locale ?? document.documentElement.dataset.icuLocale;

        const num = parseFloat(value);
        return num.toLocaleString(locale, {
            style: 'currency',
            currency: currency
        });
    });

    env.addFilter('merge', (arr, newArr) => {
        if (Array.isArray(newArr)) {
            return [...arr, ...newArr];
        }

        const key = Object.keys(newArr)[0];
        const value = Object.values(newArr)[0];
        arr[key] = value;
        return arr;
    });

    env.addFilter('split', function (str, delimiter) {
        return str.split(delimiter);
    });

    env.addFilter('raw', (value) => value);

    env.addFilter('json_encode', (value) => JSON.stringify(value));
};

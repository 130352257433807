var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/protons/icon/Icon.html.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.html.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/src/components/protons/icon/Icon.macro.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["props"], 
[], 
function (l_props, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("props", l_props);
var t_2 = "";t_2 += "\n    ";
var t_3;
t_3 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"id"),"");
frame.set("id", t_3, true);
if(frame.topLevel) {
context.setVariable("id", t_3);
}
if(frame.topLevel) {
context.addExport("id", t_3);
}
t_2 += "\n    ";
var t_4;
t_4 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"name"),"");
frame.set("name", t_4, true);
if(frame.topLevel) {
context.setVariable("name", t_4);
}
if(frame.topLevel) {
context.addExport("name", t_4);
}
t_2 += "\n    ";
var t_5;
t_5 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"size"),"");
frame.set("size", t_5, true);
if(frame.topLevel) {
context.setVariable("size", t_5);
}
if(frame.topLevel) {
context.addExport("size", t_5);
}
t_2 += "\n    ";
var t_6;
t_6 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"custom"),false);
frame.set("custom", t_6, true);
if(frame.topLevel) {
context.setVariable("custom", t_6);
}
if(frame.topLevel) {
context.addExport("custom", t_6);
}
t_2 += "\n    ";
var t_7;
t_7 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"brand"));
frame.set("brand", t_7, true);
if(frame.topLevel) {
context.setVariable("brand", t_7);
}
if(frame.topLevel) {
context.addExport("brand", t_7);
}
t_2 += "\n\n    ";
var t_8;
t_8 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"data"),{});
frame.set("data", t_8, true);
if(frame.topLevel) {
context.setVariable("data", t_8);
}
if(frame.topLevel) {
context.addExport("data", t_8);
}
t_2 += "\n    ";
var t_9;
t_9 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"classes"),[]);
frame.set("classes", t_9, true);
if(frame.topLevel) {
context.setVariable("classes", t_9);
}
if(frame.topLevel) {
context.addExport("classes", t_9);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.html.njk", false, "design-system/src/components/protons/icon/Icon.macro.njk", false, function(t_11,t_10) {
if(t_11) { cb(t_11); return; }
callback(null,t_10);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
callback(null,t_12);});
});
tasks.push(
function(result, callback){
t_2 += result;
callback(null);
});
env.waterfall(tasks, function(){
t_2 += "\n";
});
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("render");
context.setVariable("render", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/src/components/protons/icon/Icon.macro.njk"] , dependencies)
var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/protons/icon/Icon.macro.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/atoms/anchor/Anchor.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var t_1;
t_1 = env.getFilter("merge").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),[]),["nh-anchor"]);
frame.set("classes", t_1, true);
if(frame.topLevel) {
context.setVariable("classes", t_1);
}
if(frame.topLevel) {
context.addExport("classes", t_1);
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "iconPosition"),"") != "" && runtime.contextOrFrameLookup(context, frame, "iconPosition") == "right") {
var t_2;
t_2 = env.getFilter("merge").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),["nh-anchor--icon-right"]);
frame.set("classes", t_2, true);
if(frame.topLevel) {
context.setVariable("classes", t_2);
}
if(frame.topLevel) {
context.addExport("classes", t_2);
}
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "variant"),"") != "") {
var t_3;
t_3 = env.getFilter("merge").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),["nh-anchor--" + "" + runtime.contextOrFrameLookup(context, frame, "variant")]);
frame.set("classes", t_3, true);
if(frame.topLevel) {
context.setVariable("classes", t_3);
}
if(frame.topLevel) {
context.addExport("classes", t_3);
}
;
}
output += "<a\n    is=\"nh-anchor\"\n    class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "classes")," "), env.opts.autoescape);
output += "\"";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "href"),"") != "") {
output += " href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "href"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "rel"),"") != "") {
output += " rel=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "rel"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "target"),"") != "") {
output += " target=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "target"), env.opts.autoescape);
output += "\"";
;
}
frame = frame.push();
var t_6 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "data"),{});
if(t_6) {t_6 = runtime.fromIterator(t_6);
var t_4;
if(runtime.isArray(t_6)) {
var t_5 = t_6.length;
for(t_4=0; t_4 < t_6.length; t_4++) {
var t_7 = t_6[t_4][0];
frame.set("[object Object]", t_6[t_4][0]);
var t_8 = t_6[t_4][1];
frame.set("[object Object]", t_6[t_4][1]);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += " ";
output += runtime.suppressValue(t_7, env.opts.autoescape);
if(t_8) {
output += "=\"";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "\"";
;
}
;
}
} else {
t_4 = -1;
var t_5 = runtime.keys(t_6).length;
for(var t_9 in t_6) {
t_4++;
var t_10 = t_6[t_9];
frame.set("name", t_9);
frame.set("value", t_10);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += " ";
output += runtime.suppressValue(t_9, env.opts.autoescape);
if(t_10) {
output += "=\"";
output += runtime.suppressValue(t_10, env.opts.autoescape);
output += "\"";
;
}
;
}
}
}
frame = frame.pop();
output += "\n>";
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "icon")) === true && env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"name"),"") != "") {
output += "\n        ";
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.macro.njk", false, "design-system/v3/components/atoms/anchor/Anchor.html.njk", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
context.setVariable("iconTemplate", t_11);
output += "\n        ";
output += runtime.suppressValue((lineno = 20, colno = 30, runtime.callWrap(runtime.memberLookup((t_11),"render"), "iconTemplate[\"render\"]", context, [{"name": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"name"),"size": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"size"),"1"),"custom": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"custom"),false),"brand": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"brand")),"classes": ["nh-anchor__icon"]}])), env.opts.autoescape);
})});
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "label"),"") != "") {
output += "\n        <span class=\"nh-anchor__label\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "label"), env.opts.autoescape);
output += "</span>";
;
}
output += "</a>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/atoms/anchor/Anchor.html.njk"] , dependencies)